import $ from 'jquery';
window.$ = window.jQuery = $;

import Backbone from '../../../bower_components/backbone.marionette/lib/backbone.marionette';
import Mustache from '../../../bower_components/mustache/index';
import isMobile from './utils/app.utils.ismobile';

// create the Marionette application
var App = window.App = new Backbone.Marionette.Application();

// use mustache
var tmplCache = Backbone.Marionette.TemplateCache;
tmplCache.prototype.compileTemplate = function (rawTemplate) {
    return Mustache.compile(rawTemplate);
};

function bindEvents() {
    $(document).on('keyup', function (ev) {
        return;
        var playing = App.mix.get('playing'),
            position = App.mix.get('position');
        switch (ev.keyCode) {
            // spacebar
            case 32:
                if (!playing) {
                    App.mix.play();
                } else {
                    App.mix.pause();
                }
                break;
            // r
            case 82:
                App.mix.play(0);
                if (!playing) {
                    App.mix.pause();
                }
                break;
            // left arrow
            case 37:
                if (playing) {
                    App.mix.play(position - 10);
                }
                break;
            case 39:
                if (playing) {
                    App.mix.play(position + 10);
                }
                break;
        }
    });


    /**
    document.addEventListener('touchmove', function(ev) {
        console.debug('touchmove', ev);
        App.vent.trigger('mixer-mousemove', ev);
    }, {passive:false});
    document.addEventListener('mouseup', function(ev) {
        App.vent.trigger('mixer-mouseup', ev);
    }, {passive:false});

    document.addEventListener('touchstart', function(ev) {
        console.log('touch', ev);
        //App.vent.trigger('mixer-mousemove', ev);
        //ev.preventDefault();
    }, {passive:false});
     */
    /**
    document.addEventListener('mouseup', function(ev) {
        App.vent.trigger('mixer-mouseup', ev);
    }, {passive:false});
    document.addEventListener('touchend', function(ev) {
        App.vent.trigger('mixer-mouseup', ev);
        ev.preventDefault();
    }, {passive:false});
    */


    $('#mixer-row').on('mousemove', '.channel', function (ev) {
        //console.debug('mousemove touchmove', ev);
        App.ac.resume();
        App.vent.trigger('mixer-mousemove', ev);
    });
    $('#mixer-row').on('touchmove', '.fader', function (ev) {
        //console.debug('mousemove touchmove', ev);
        App.ac.resume();
        App.vent.trigger('mixer-mousemove', ev);
        ev.preventDefault();
    });
    $('#mixer-row').on('touchstart', '.fader', function (ev) {
        //console.debug('mousemove touchmove', ev);
        App.ac.resume();
        App.vent.trigger('mixer-mousemove', ev);
        ev.preventDefault();
    });
    $('#mixer-row').on('mouseup touchend', function (ev) {
        App.ac.resume();
        //console.debug('mouseup touchend', ev);
        App.vent.trigger('mixer-mouseup', ev);
        if (ev.type === 'touchend') {
            //ev.preventDefault();
        }
    });

    App.vent.on('mixer-mouseup', App.disableDrag);
    App.vent.on('mixer-mousemove', App.dragHandler);

    //$('#mixer-row .fader').on('mousedown touchstart', App.enableDrag);
}

// config
App.tracks = 0;
App.loaded = 0;
App.ready = false;

App.isMobile = (window.innerWidth <= 680) || ((navigator.userAgent.match(/iPad/i) && (window.innerHeight > window.innerWidth)));


//App.isMobile = (window.innerWidth <= 680 || window.innerHeight <= 800);

App.vuLeftData = [];
App.vuRightData = [];

// add an AudioContext
App.ac = (function (w) {
    var Ac = w.AudioContext || w.webkitAudioContext || w.mozAudioContext;
    return new Ac();
}(window));

// wait for all tracks to be loaded
App.vent.on('loaded', function () {
    var top;
    var height = App.isMobile ? 137 : 214;
    if (++App.loaded === App.tracks) {
        App.ready = true;
        App.vent.trigger('ready');
        top = App.util.scale(App.mix.get('gain'), 0, 1.5, height, 0);
        $('#master .fader').css('top', top + 'px');
    }
});

// Build tracks collection view
App.vent.on('ready', function () {
    App.trackViews = new App.Views.Tracks({
        collection: App.mix.attributes.tracks
    });
    App.controlsView = new App.Views.Controls({
        model: App.mix
    });
    App.trackViews.render();
    App.vuLeft = $('.needle.left');
    App.vuRight = $('.needle.right');
    bindEvents();
    if (!('ontouchstart' in window)) {
        //App.mix.play();
    }
});

// rAF loop for meters
App.vent.on('anim-tick', function () {
    var left;
    if (!App.vuLeft || !App.mix.get('playing')) {
        return;
    }
    App.mix.levels();
    left = App.mix.get('dBFSLeft');
    left = Math.max(-20, App.util.scale( left + 20, -20, 0, 0, 60 ));

    App.vuLeftData.unshift(left);

    if (App.vuLeftData.length >= 36) {
        App.vuLeftData.length = 36;
    }

    if (App.vuRightData.length > 18) {
        App.vuRightData.length = 18;
    }

    left = App.vuLeftData.reduce(function (sum, curr) {
        return sum + curr;
    }, 0) / App.vuLeftData.length;

    App.vuLeft = $('.channel.activated .vu .needle.left');

    App.vuLeft.css('transform', 'rotate(' + left + 'deg)');
});

App.dragState = {
    x: null,
    y: null,
    px: null,
    prop: null,
    $target: null
};

App.enableDrag = function (ev) {

    //console.log('enableDrag', ev);

    var $elem = $(ev.currentTarget), deg, touch;
    if (ev.type === 'touchstart' && ev.originalEvent.changedTouches) {
        //console.log('4', $elem.hasClass('fader'), ev.originalEvent);
    }

    if ($elem.hasClass('fader')) {
        App.faderCanDrag = true;
        App.dragState.px = parseInt($elem.css('top'), 10);
    }
    if (ev.type === 'touchstart' && ev.changedTouches) {
        touch = ev.changedTouches[0];
        App.dragState.x = touch.pageX;
        App.dragState.y = touch.pageY;
    } else if(ev.type === 'touchmove' && ev.originalEvent.changedTouches[0]) {
        console.log('2');
        touch = ev.originalEvent.changedTouches[0];
        App.dragState.x = touch.pageX;
        App.dragState.y = touch.pageY;
    } else {
        //console.log(ev.pageY);
        App.dragState.x = ev.pageX;
        App.dragState.y = ev.pageY;
    }

    //console.log('enableDrag-end', App.faderCanDrag, touch, ev);

    App.dragState.$target = $elem;
};

App.disableDrag = function () {
    //console.log('disableDrag', App.faderCanDrag);
    if (App.faderCanDrag) {
        App.faderCanDrag = false;
    }
};

App.dragHandler = function (ev) {
    if (!App.faderCanDrag) {
        return;
    }
    var height = App.isMobile ? 137 : 220;

    var touch = ev.pageY;

    if(ev.type === 'touchstart' && ev.changedTouches) {
        touch = ev.changedTouches;
    } else if (ev.type === 'touchmove' && ev.originalEvent.changedTouches) {
        touch = ev.originalEvent.changedTouches;
    }

    if (ev.type === 'touchstart' && ev.changedTouches) {
        touch = ev.changedTouches[0];
        App.dragState.x = touch.pageX;
        App.dragState.y = touch.pageY;
    } else if(ev.type === 'touchmove' && ev.originalEvent.changedTouches[0]) {
        touch = ev.originalEvent.changedTouches[0];
        App.dragState.x = touch.pageX;
        App.dragState.y = touch.pageY;
    } else {
        App.dragState.x = ev.pageX;
        App.dragState.y = ev.pageY;
    }

    //console.log('dragHandler-touch', touch);

    var pos = touch && touch[0] ? touch[0].pageY : ev.pageY,
        state = App.dragState.y,
        delta = pos - state,
        css = App.dragState.px + delta;

    css = Math.min(height, css);
    css = Math.max(0, css);
    App.dragState.$target.css('top', css + 'px');

    //console.log('App=dragHandler-css', css);

    //App.mix.set('gain', App.util.scale(css, 0, height, 1.5, 0));
};

App.resetFader = function () {
    var height = App.isMobile ? 137 : 220;
    var top = App.util.scale(1, 0, 1.5, height, 0);
    $('#master .fader').css('top', top + 'px');
    App.mix.set('gain', 1);
};

// create the mix and start the app on DOM ready
$(function () {

    App.mix = new App.Models.Mix();

    function startup() {
        App.mix.fetch();
        App.start();
    }

    startup();

});