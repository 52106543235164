App.module('Collections', function( Collections, App, Backbone,
  Marionette, $, _ ) {

  'use strict';

  var Tracks = Collections.Tracks = Backbone.Collection.extend({

    model: App.Models.Track,

    // begin playback of all tracks
    play: function( time ) {
      var _this = this;
      App.ac.resume().then(function() {
        _this.each(function (track) {
          track.play(time);
        });
        return _this;
      });
    },

    // pause all tracks
    pause: function() {
      var _this = this;
      App.ac.resume().then(function() {
        _this.each(function (track) {
          track.pause();
        });
        return this;
      });
    },

    // get max track duration (essentially song length)
    maxLength: function() {
      var durations = App.mix.get('tracks').map(function( track ) {
        return track.get('duration');
      });
      return Math.max.apply(Math, durations);
    }

  });

});
