App.module('Views', function (Views, App, Backbone, Marionette, $, _) {

	'use strict';

	var Track = Views.Track = Marionette.ItemView.extend({
		template: '#tmpl-track',

		events: {
			'mousedown .fader': 'enableDrag',
			'touchstart .fader': 'enableDrag',
			'touchmove .fader': 'enableDrag',
			//'mousemove .fader': 'enableDrag',
			'dblclick .fader': 'resetFader',
			'mousedown .panner': 'enableDrag',
			'touchstart .panner': 'enableDrag',
			'dblclick .panner': 'resetPanner',
			'click .mute': 'mute',
			'touchstart .mute': 'mute',
			'click .solo': 'solo',
			'touchstart .solo': 'solo',
			'click .afl': 'afl',
			'touchstart .afl': 'afl',
			'click .label': 'toggleFader',
			//'touchstart .label'   : 'toggleFader',
		},

		ui: {
			canvas: '.meter'
		},

		toggleFader: function (ev) {
			var playing;

			//console.log('1', this, ev);

			var channel = this.ui.canvas[0].getContext('2d').canvas.parentNode.parentNode.parentNode;
			var activated = channel.classList.contains('activated');


			var height = App.isMobile ? 137 : 220;

			var tracks = App.mix.get('tracks');
			var me = this;

			if (App.mix.playing !== true) {
				App.mix.play(0);
			}

			tracks.forEach(function (track) {
				if (track.cid !== me.model.cid) {
					track.pause();
					track.set('gain', 0);
				}
			});
			$('.channel').removeClass('activated');
			$('.fader').css('top', App.util.scale(0, 0, 1.15, height, 0) + 'px');

			if (!activated) {
				tracks.forEach(function (track) {
					if (track.cid === me.model.cid) {
						track.play(0);
					}
				});

				channel.classList.add('activated');
				this.model.set('gain', 0.5);
				this.$el.find('.fader').css('top', App.util.scale(0.5, 0, 1.15, height, 0) + 'px');

				typeout('.typeout', $(ev.currentTarget).text(), {
					max: 110,
					min: 40,
					numLoops: 100,
				});


				//this.model.solo();
				//this.model.unmute();

			} else {

				channel.classList.remove('activated');
				this.model.set('gain', 0);
				//this.model.mute();
				this.$el.find('.fader').css('top', App.util.scale(0, 0, 1.15, height, 0) + 'px');

			}

		},

		modelEvents: {
			'change:muted': 'render',
			'change:soloed': 'render',
			'change:afl': 'render'
		},

		initialize: function () {
			App.vent.on('mixer-mouseup', this.disableDrag.bind(this));
			App.vent.on('mixer-mousemove', this.dragHandler.bind(this));
			App.vent.on('anim-tick', this.drawMeter.bind(this));
			App.vent.on('mix-pause', function () {
				setTimeout(function () {
					this.drawMeter();
				}.bind(this), 50);
			}.bind(this));
		},

		onBeforeRender: function () {
			this.$el.addClass('channel');
		},

		onRender: function () {
			var canvas = this.ui.canvas[0],
				height = canvas.height,
				width = canvas.width,
				hue = 180,
				i = 0,
				ctx;
			this.offscreen = document.createElement('canvas');
			this.offscreen.width = canvas.width;
			this.offscreen.height = canvas.height;
			ctx = this.offscreen.getContext('2d');
			ctx.fillStyle = 'hsl(' + hue + ', 100%, 40%)';
			while (i < height) {
				hue += ((1 - i / height) * 0.6);
				ctx.fillStyle = 'hsl(' + hue + ', 100%, 40%)';
				ctx.fillRect(0, height - i, width, 2);
				i += 3;
			}
		},

		serializeData: function () {
			var data = this.model.toJSON();

			var height = App.isMobile ? 137 : 220;
			data.gain = App.util.scale(Math.sqrt(data.gain), 0, 1.15, height, 0);
			data.pan = App.util.scale(data.pan, -1, 1, -150, 150);
			data.muted = data.muted ? 'active' : '';
			data.soloed = data.soloed ? 'active' : '';
			data.afl = data.afl ? '' : 'active';
			return data;
		},

		faderCanDrag: false,

		pannerCanDrag: false,

		dragState: {
			x: null,
			y: null,
			px: null,
			prop: null,
			$target: null
		},

		enableDrag: function (ev) {
			var $elem = $(ev.currentTarget), deg, touch;

			//console.log('enableFader-val', ev.type, this.dragState.x, this.dragState.y, this.dragState.px);

			if (ev.type === 'touchstart' && ev.changedTouches) {
				touch = ev.changedTouches[0];
				//console.log('1', touch);
				this.dragState.x = touch.pageX;
				this.dragState.y = touch.pageY;
			} else if (ev.type === 'touchmove' && ev.originalEvent.changedTouches) {
				if(this.dragState.y !== null) {
					return;
				}
				touch = ev.originalEvent.changedTouches[0];
				//console.log('2', touch);
				this.dragState.x = touch.pageX;
				this.dragState.y = touch.pageY;
			} else {
				this.dragState.x = ev.pageX;
				this.dragState.y = ev.pageY;
			}

			if ($elem.hasClass('fader')) {
				this.faderCanDrag = true;
				this.dragState.px = parseInt($elem.css('top'), 10);
			}

			//console.log('enableDrag-dragState-px', $elem, this.dragState.px, this.dragState);

			this.dragState.$target = $elem;

			//console.log('enableFader-val-2', ev.type, this.dragState.x, this.dragState.y, this.dragState.px);

		},

		disableDrag: function (ev) {
			//console.log('8');
			if (this.faderCanDrag || this.pannerCanDrag) {
				this.faderCanDrag = false;
				this.pannerCanDrag = false;
			}
		},

		dragFader: function (ev, max) {

			//console.log('touchDrag', ev, (typeof ev.originalEvent == 'undefined'));

			//console.log('dragFader', ev);

			//console.log('dragFader-css', 'pos:'+ pos, 'state:'+ state, 'delta:'+ delta, 'css:'+ css, 'dragState:' + this.dragState.px);

			 var touch = ev.pageY;

			 if (ev.type === 'touchstart' && ev.changedTouches[0]) {
				touch = ev.changedTouches;
			} else if (ev.type === 'touchmove' && ev.originalEvent.changedTouches[0]) {
				touch = ev.originalEvent.changedTouches;
			}
			 var pos = touch && touch[0] ? touch[0].pageY : ev.pageY,
			 state = this.dragState.y,
			 delta = pos - state,
			 css = this.dragState.px + delta;


			//console.log('1', (ev.type === 'touchstart' && ev.originalEvent.changedTouches) || (ev.type === 'touchmove' && ev.changedTouches), touch[0]);
			css = Math.min(max, css);
			css = Math.max(0, css);

			//console.log('dragFader', this.$el);

			//console.log('dragFader-target', this.dragState.$target);

			var height = App.isMobile ? 137 : 220;
			//css = App.isMobile ? css * 0.62 : css;

			var gain = Math.pow(App.util.scale(css, 0, height, 1.15, 0), 2);

			//console.log('dragFader-gain', css, pos, this.dragState);

			this.model.set('gain', gain);

			var channel = this.$el;
			if (!channel.hasClass('channel')) {
				channel = channel.find('.channel');
			}
			var fader = this.dragState.$target.find('.fader');
			var activated = channel.hasClass('activated');

			//console.log('dragFader-el', activated, channel);

			if (App.mix.get('playing') !== true) {
				App.mix.play(0);
			}
			var tracks = App.mix.get('tracks');
			var me = this;

			if (!channel.hasClass('activated')) {

				//console.log('dragFader-deactivated', gain, css, fader);

				//this.dragState.$target.css('top', css + 'px');
				//fader.css('top', css + 'px');

				if (gain > 0.0) {
					//console.log('hasGain', gain);

					tracks.forEach(function (track) {
						if (track.cid !== me.model.cid) {
							track.pause();
							track.set('gain', 0);
							//track.unsolo();
							//track.mute();
						} else {
							track.play(0);
						}
					});

					$('.channel').removeClass('activated');
					$('.fader').css('top', App.util.scale(0, 0, 1.15, height, 0) + 'px');

					channel.addClass('activated');

					//this.model.solo();
					//this.model.unmute();

					var selected_button = channel.find('button.label');

					if (selected_button.length > 1) {
						$.each(selected_button, function (index, e) {
							if ($(e).css('display') === 'block') {
								selected_button = $(e);
							}
						});
					}

					typeout('.typeout', selected_button.text(), {
						max: 110,
						min: 40,
						numLoops: 100,
					});

				} else {

					tracks.forEach(function (track) {
						track.pause();
						track.set('gain', 0);
					});

					$('.channel').removeClass('activated');
					$('.fader').css('top', App.util.scale(0, 0, 1.15, height, 0) + 'px');

				}

				//fader.css('top', css + 'px');
				this.dragState.$target.css('top', css + 'px');

			} else {

				//console.log('dragFader-activated-drag', gain, css, fader);

				if (gain > 0.0) {
					tracks.forEach(function (track) {
						if (track.cid !== me.model.cid) {
							track.pause();
							track.set('gain', 0);
							//track.unsolo();
							//track.mute();
						} else {
							track.play(0);
						}
					});
					//console.log('hasGain', gain);
					//fader.css('top', App.util.scale(0, 0, 1.15, height, 0) + 'px');
					this.dragState.$target.css('top', css + 'px');
					//fader.css('top', css + 'px');
				} else {

					//console.log('noGain', gain);
					$('.channel').removeClass('activated');
					$('.fader').css('top', App.util.scale(0, 0, 1.15, height, 0) + 'px');

					tracks.forEach(function (track) {
						track.pause();
						track.set('gain', 0);
					});
					//this.model.unsolo();
					//this.model.mute();
				}

			}

		},

		dragPanner: function (ev) {
			var touch = ev.type === 'touchmove' && ev.originalEvent.changedTouches,
				width = this.dragState.width,
				height = this.dragState.height,
				offset = this.dragState.offset,
				top = touch && touch[0] ? touch[0].pageY : ev.pageY,
				left = touch && touch[0] ? touch[0].pageX : ev.pageX,
				a = offset.left + (width / 2) - left,
				b = offset.top + (height / 2) - top,
				deg = -1 * Math.atan2(a, b) * (180 / Math.PI);
			if (deg >= -150 && deg <= 150) {
				this.dragState.$target.css('transform', 'rotate(' + deg + 'deg)');
				this.model.set('pan', App.util.scale(deg, -150, 150, -1, 1));
			}
		},

		dragHandler: function (ev) {
			var height = App.isMobile ? 137 : 220;
			//console.log('4', this.faderCanDrag);
			if (this.faderCanDrag) {
				this.dragFader(ev, height);
			} else if (this.pannerCanDrag) {
				this.dragPanner(ev);
			}
		},

		resetFader: function () {
			var height = App.isMobile ? 137 : 220;
			var top = App.util.scale(1, 0, 1.15, height, 0);
			this.$el.find('.fader').css('top', top + 'px');
			this.model.set('gain', 1);
		},

		resetPanner: function () {
			this.$el.find('.panner').css('transform', 'rotate(0deg)');
			this.model.set('pan', 0);
		},

		mute: function (ev) {
			var muted;
			if (ev && 'ontouchstart' in window && ev.type === 'click') {
				//return;
			}
			muted = this.model.get('muted');
			if (muted) {
				this.model.unmute();
			} else {
				this.model.mute();
			}
		},

		solo: function (ev) {
			var soloed;
			return;
			if (ev && 'ontouchstart' in window && ev.type === 'click') {
				return;
			}
			soloed = this.model.get('soloed');
			if (soloed) {
				this.model.unsolo();
			} else {
				this.model.solo();
			}
		},

		afl: function (ev) {
			if (ev && 'ontouchstart' in window && ev.type === 'click') {
				return;
			}
			this.model.set('afl', !this.model.get('afl'));
		},

		drawMeter: function () {
			if (typeof this.ui.canvas !== 'string') {
				this.model.levels();
				var canvas = this.ui.canvas[0],
					ctx = canvas.getContext('2d'),
					dBFS = this.model.attributes.dBFS,
					gain = this.model.attributes.gain,
					afl = this.model.attributes.afl,
					height = this.cHeight || (this.cHeight = canvas.height),
					width = this.cWidth || (this.cWidth = canvas.width),
					scaled = App.util.scale(-dBFS, 48, 0, 0, height),
					now = Date.now(),
					peakTime = this.peakTime || -Infinity,
					peak = this.peak || 0,
					timeDiff = now - peakTime,
					freshness;
				if (afl) {
					scaled = scaled * gain;
				}
				scaled = Math.max(0, scaled - (scaled % 3));
				if (this.dirty) {
					ctx.clearRect(0, 0, width, height);
					this.dirty = false;
				}
				if (scaled >= 3) {
					ctx.drawImage(this.offscreen, 0, height - scaled, width, scaled,
						0, height - scaled, width, scaled
					);
					this.dirty = true;
				}
				// save new peak
				if (scaled >= peak) {
					peak = this.peak = scaled;
					this.peakTime = now;
					timeDiff = 0;
				}
				// draw existing peak
				if (timeDiff < 1000 && peak >= 1) {
					// for first 650 ms, use full alpha, then fade out
					freshness = timeDiff < 650 ? 1 : 1 - ((timeDiff - 650) / 350);
					ctx.fillStyle = 'rgba(238,119,85,' + freshness + ')';
					ctx.fillRect(0, height - peak - 1, width, 1);
					this.dirty = true;
					// clear peak
				} else {
					this.peak = 0;
					this.peakTime = now;
				}
			}
		}

	});

});
